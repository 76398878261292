<template>
	<div class="container-fluid">
		<div class="row">
			<div class="col-lg-12">
				<div class="d-flex flex-wrap flex-wrap align-items-center justify-content-center mb-0">
					<div class="text-center">
						<h4 class="mb-3">{{title}}</h4>
						<p class="mb-0">
							{{desc1}}<br>
							{{desc2}}<br>
							{{desc3}}
						</p>
						<p v-b-modal.modal-3 variant="link" class="mb-0">
							<u>랭킹 모니터링 상품 추가 방법</u>
						</p>
						<div v-if="showspinner">
							<img
								style="max-width:10%;"
								:src="require('../../../assets/images/small/loading.gif')"
							>
							{{loadingtext}}
						</div>
						<b-modal
							id="modal-3"
							size="xl"
							title="쿠팡 링크추적 상품 추가 방법"
							ok-only
						>
							<p>
								<a
									href="https://chrome.google.com/webstore/detail/couplus/bogokmehpkdjafnlhjhinfgmeljdgmgh?hl=ko&authuser=0"
									target="_blank"
								>
									<u>구글웹스토어</u>
								</a>에 접속해서 "COUPLUS"를 다운받은 후 쿠플러스에 로그인한 다음
								<b style="color:red">
									<u>"쿠팡 상품페이지"에서</u>
								</b> "링크수집(랭킹)" 버튼을 클릭한 후 모니터링할 키워드를 입력해 주세요.<br>
								한번 추가된 상품은 매일 1회 자동으로 수집됩니다.
							</p>
							<img
								style="max-width:100%;"
								:src="require('../../../assets/images/small/랭킹모니터링추가.png')"
							>
						</b-modal>
					</div>
				</div>
			</div>

			<div class="col-lg-12 mt-3" v-if="showlist">
				<!-- 순서 편집 모드 토글 및 툴바 -->
				<div class="mb-3 d-flex justify-content-between align-items-center">
					<div class="d-flex align-items-center">
						<b-button variant="primary" class="mr-2" @click="collectRank">현재랭킹수집</b-button>
						<b-button variant="secondary" class="mr-2" @click="deleteitem">삭제</b-button>
						<b-button variant="success" @click="download">다운로드</b-button>
					</div>
					<div class="d-flex align-items-center">
						<b-button
							variant="outline-primary"
							class="mr-2"
							@click="toggleOrderEditMode"
						>
							{{ isOrderEditMode ? '순서 편집 종료' : '순서 편집' }}
						</b-button>

						<!-- 순서 편집 툴바 -->
						<div v-if="isOrderEditMode" class="d-flex align-items-center">
							<b-button-group class="mr-2">
								<b-button
									variant="outline-secondary"
									:disabled="!selectedItem && !selectedKeyword"
									@click="moveToTop"
									v-b-tooltip.hover title="맨 위로"
								>
									▲
								</b-button>
								<b-button
									variant="outline-secondary"
									:disabled="!selectedItem && !selectedKeyword"
									@click="moveUp"
									v-b-tooltip.hover title="위로"
								>
									△
								</b-button>
								<b-button
									variant="outline-secondary"
									:disabled="!selectedItem && !selectedKeyword"
									@click="moveDown"
									v-b-tooltip.hover title="아래로"
								>
									▽
								</b-button>
								<b-button
									variant="outline-secondary"
									:disabled="!selectedItem && !selectedKeyword"
									@click="moveToBottom"
									v-b-tooltip.hover title="맨 아래로"
								>
									▼
								</b-button>
							</b-button-group>

							<b-button
								variant="success"
								@click="saveOrder"
								:disabled="!hasOrderChanged"
								class="mr-2"
							>
								순서 저장
							</b-button>

							<b-button
								variant="danger"
								@click="cancelOrderEdit"
							>
								편집 취소
							</b-button>
						</div>

						<!-- 기존 버튼들 -->
						
					</div>
				</div>

				<div class="rounded mb-3">
					<CDataTable
						ref="dataTable"
						:headers="tableHeaders"
						:items="tableItems"
						:height="'700px'"
						:show-select="!isOrderEditMode"
						:item-key="'productId'"
						v-model="tableSelectedItem"
						@input="handleSelectionChange"
					>
						<!-- Product Info Column -->
						<template v-slot:판매상품명="{ item }">
							<div 
								class="d-flex align-items-center"
							>
								<div v-if="isOrderEditMode" class="mr-2">
									<input
										type="radio"
										:checked="selectedItem && selectedItem.productId === item.productId"
										@click="selectItem(item)"
										name="item-select"
									>
								</div>
								<div class="d-flex align-items-center product-info" v-b-modal="'modal'+item.index">
									<img :src="item.썸네일" class="img-fluid rounded avatar-80 mr-3" alt="image">
									<div>
										{{item.판매상품명}}
										<p class="mb-0">
											<small>노출ID: {{item.productId}}</small>
										</p>
									</div>
								</div>
							</div>
						</template>

						<!-- Keywords Column -->
						<template v-slot:keywords="{ item }">
							<div 
								v-for="(keyword, kIdx) in item.키워드" 
								:key="kIdx" 
								class="keyword-row"
								style="font-size:90%;line-height:130%"
								:class="{ 'keyword-row-highlight': highlightedRow === item.index && highlightedKeywordIndex === kIdx }"
								@mouseover="highlightKeywordRow(item.index, kIdx)"
								@mouseleave="clearHighlight"
							>
								<div v-if="isOrderEditMode" class="d-flex align-items-center">
									<input
										type="radio"
										:checked="selectedKeyword && selectedKeyword.itemIndex === item.index && selectedKeyword.keywordIndex === kIdx"
										@click="selectKeyword(item.index, kIdx, keyword)"
										name="keyword-select"
										class="mr-2"
									>
									<span>{{keyword}}</span>
								</div>
								<span v-else>{{keyword}}</span>
							</div>
							<div v-if="showTraffic" class="badge bg-success mb-1" style="width:30%;text-align:center;display: flex;justify-content: center;">월조회수</div>
							<div v-if="showTrafficDetail" class="badge bg-success mb-1" style="width:30%;text-align:center;display: flex;justify-content: center;">클릭수</div>
							<div v-if="showTrafficDetail" class="badge bg-primary mb-1" style="width:30%;text-align:center;display: flex;justify-content: center;">판매수</div>
						</template>

						<!-- Rank Changes Column -->
						<template v-slot:rankChanges="{ item }">
							<div
								v-for="(rank, rIdx) in item.랭킹변화"
								:key="rIdx"
								:class="[
									rank > 0 ? 'text-primary' : rank < 0 ? 'text-secondary' : '',
									{ 'keyword-row-highlight': highlightedRow === item.index && highlightedKeywordIndex === rIdx }
								]"
								@mouseover="highlightKeywordRow(item.index, rIdx)"
								@mouseleave="clearHighlight"
							>
								{{ rank === 0 ? "-" : rank }}
								<span class="text-primary" v-if="rank > 0">▲</span>
								<span class="text-secondary" v-if="rank < 0">▼</span>
							</div>
							<div v-if="showTraffic">-</div>
							<div v-if="showTrafficDetail">-</div>
							<div v-if="showTrafficDetail">-</div>
						</template>

						<!-- Dynamic Date Columns -->
						<!-- !!! key 속성 추가 X (원본 유지) !!! -->
						<template v-for="(date, dIdx) in dates" v-slot:[`date_${date}`]="{ item }">
							<div
								v-for="(keywordRank, kIdx) in item.순위"
								:key="`${item.index}-${kIdx}-${dIdx}`"
								:class="{ 'keyword-row-highlight': highlightedRow === item.index && highlightedKeywordIndex === kIdx }"
								@mouseover="highlightKeywordRow(item.index, kIdx)"
								@mouseleave="clearHighlight"
							>
								{{keywordRank[dIdx] !== undefined ? keywordRank[dIdx] : '-'}}
							</div>
							<div
								v-if="showTraffic"
								:key="`traffic-${item.index}-${dIdx}`"
								class="text-success fw-bolder"
							>
								{{ item.조회수[dIdx] !== undefined ? item.조회수[dIdx] : '-' }}
							</div>
							<div
							v-if="showTrafficDetail"
							:key="`traffic-click-${item.index}-${dIdx}`"
							class="text-success fw-bolder"
							>
							{{ item.클릭수[dIdx] !== undefined ? item.클릭수[dIdx] : '-' }}
							</div>
							<div
							v-if="showTrafficDetail"
							:key="`traffic-sale-${item.index}-${dIdx}`"
							class="text-primary fw-bolder"
							>
							{{ item.판매수[dIdx] !== undefined ? item.판매수[dIdx] : '-' }}
							</div>

						</template>

						<!-- Actions Column -->
						<template v-slot:삭제="{ item }">
							<div
								v-for="(keyword, kIx) in item.키워드"
								:key="kIx"
								class="action-row"
								:class="{ 'keyword-row-highlight': highlightedRow === item.index && highlightedKeywordIndex === kIx }"
								@mouseover="highlightKeywordRow(item.index, kIx)"
								@mouseleave="clearHighlight"
							>
								<a
									class="badge bg-warning mr-2"
									style="height:90%"
									data-toggle="tooltip"
									v-b-tooltip.top
									title="Delete"
									href="javascript:void(0)"
									@click="deletelist(item, keyword)"
								>
									<i class="ri-delete-bin-line mr-0"></i>
								</a>
							</div>
							<div
								v-if="showTraffic"
								class="placeholder-row"
							></div>
							<div
								v-if="showTrafficDetail"
								class="placeholder-row"
							></div>
							<div
								v-if="showTrafficDetail"
								class="placeholder-row"
							></div>
						</template>
					</CDataTable>
				</div>
			</div>

			<!-- 랭킹세부이력 모달 -->
			<b-modal
				v-for="(item, index) in saleslists"
				:key="index"
				:id="'modal'+index"
				size="xl"
				scrollable
				title="랭킹세부이력"
				ok-title="OK"
				cancel-title="Close"
				class="detail-modal"
				style="font-size:80%"
			>
				<div class="row">
					<div class="col-12">
						<a :href="item.url" target="_blank">
							<card style="padding:10px;">
								<div
									class="d-flex align-items-center"
									style="margin:auto;padding:0px"
								>
									<img
										:src="item.썸네일"
										class="img-fluid rounded avatar-120 mr-3"
										alt="image"
									>
									<div>
										<p>판매상품명 : {{item.판매상품명}}</p>
										<p>노출ID : {{item.productId}}</p>
									</div>
								</div>
							</card>
						</a>
					</div>
					<div class="col-12">
						※ 최대 60일로 표시되며 그 이상 수집하는 경우 오래된 순부터 자동 삭제됩니다.
						<div class="table-responsive">
							<table
								id="datatable"
								class="table data-table table-striped dataTable text-center"
							>
								<thead>
									<tr class="ligth">
										<th>항목</th>
										<th
											v-for="(date, dIdx) in item.상세순위[0].ranks.map(r => r.date)"
											:key="dIdx"
										>
											{{date}}
										</th>
									</tr>
								</thead>
								<tbody>
									<tr v-if="showTraffic">
										<td class="badge bg-success">조회수</td>
										<td
											v-for="(click, cIdx) in item.조회수"
											:key="cIdx"
											class="text-success fw-bold"
										>
											{{click}}
										</td>
									</tr>
									<tr
										v-for="(keywordRanks, kIdx) in item.상세순위"
										:key="kIdx"
									>
										<td>{{item.키워드[kIdx]}}</td>
										<td
											v-for="(rank, rIdx) in keywordRanks.ranks"
											:key="rIdx"
										>
											{{rank.rank}}
											<span
												:class="rank.change >= 0 ? 'text-primary' : 'text-secondary'"
											>
												({{rank.change === 0 ? "-" : rank.change}})
											</span>
										</td>
									</tr>
								</tbody>
							</table>
						</div>
					</div>
				</div>
			</b-modal>
		</div>
	</div>
</template>

<script>
import axios from 'axios';
import * as XLSX from 'xlsx';
// import { core } from '../../../config/pluginInit';
import CDataTable from "@/components/common/CDataTable";

export default {
	name: 'ranking',
	components: {
		CDataTable
	},
	data() {
		return {
			showTraffic: true,
			showTrafficDetail: false,
			salesarr: [],
			importarr: [],
			stockarr: [],
			data: [],             // 모든 Trackingrank 문서(상품*키워드)
			originalData: [],     // 초기에 불러온 상태를 복제(순서 변경 감지용)
			checkalllists: false,
			loadingtext: '데이터 불러오는 중 ...',
			title: "랭킹 추적",
			desc1: "랭킹 추적할 상품을 확장프로그램에서 추가해주세요.",
			desc2: "랭킹은 00시부터 하루 1회 순차적으로 수집됩니다.",
			desc3: "베이직버전은 30슬롯까지, 프리미엄버전은 무제한슬롯으로 추가가 가능합니다.",
			showspinner: false,
			saleslists: [],       // 화면에 표시할 (productId 단위) 그룹화
			saleslistsinit: [],
			showlist: false,
			dates: [],
			datesinit: [],
			isMultipleDelete: false,
			deleteQueue: [],
			deleteTimeout: null,
			tableHeaders: [
				{ text: '상품정보', value: '판매상품명', align:'center', width:350, isSlot: true },
				{ text: 'keywords', value: 'keywords', align:'center', width:170, isSlot: true },
				{ text: '랭킹변화', value: 'rankChanges', align:'center', width:100, isSlot: true },
				// Date headers
				{ text: '삭제', value: '삭제', align:'center', width:100, isSlot: true }
			],
			date: null,
			tableSelectedItem: [],
			isOrderEditMode: false,
			selectedItem: null,
			selectedKeyword: null,
			hasOrderChanged: false,
			originalOrder: null,
			highlightedRow: null,
			highlightedKeywordIndex: null,
			growthSales:[],
		};
	},
	beforeDestroy() {
		window.removeEventListener('message', this.messageEventHandler);
	},
	computed: {
		tableItems() {
			return this.saleslists.map((item, index) => ({
				...item,
				index: index  // 명시적으로 index 할당
			}));
		}
	},
	watch: {
		dates: {
			handler(newDates) {
				if (newDates && newDates.length > 0) {
					this.updateTableHeaders();
					this.date = newDates[0] || null;
				}
			},
			deep: true
		}
	},
	mounted() {
		this.messageEventHandler = async event => {
			if (event.data.res && event.data.res === 'keywordanalysisstatusratio') {
				this.showspinner = false;
				this.loadingtext = event.data.text + '...(' + event.data.status + ")";
				this.$nextTick(() => {
					this.showspinner = true;
				});
			}
			if (event.data.res && event.data.res === 'collectRankResult') {
				var ele = event.data.result;
				const idx = this.data.findIndex(e => e.productId == ele.productId && ele.keyword == e.keyword);
				if (idx !== -1) {
					this.data[idx] = ele;
					this.showlist = false;
					this.$nextTick(() => {
						this.searchresult();
					});
				}
			}
		};
		window.addEventListener('message', this.messageEventHandler);
		this.$nextTick(() => {
			if (this.$store.state.user.auth.showsales) {
				this.showTraffic = true;
			}
			this.search();
		});
	},
	methods: {
		updateDateData() {
			this.date = this.dates[0] || null;
			this.showlist = false;
		},
		updateTableHeaders() {
			const baseHeaders = [
				{ text: '상품정보', value: '판매상품명', align:'left', width:380, isSlot: true },
				{ text: 'keywords', value: 'keywords', align:'center', width:180, isSlot: true },
				{ text: '랭킹변화', value: 'rankChanges', align:'center', width:80, isSlot: true }
			];
			const dateHeaders = this.dates.map(date => ({
				text: date,
				value: `date_${date}`,
				align:'center',
				width:80,
				isSlot: true
			}));
			const actionsHeader = { text: '삭제', value: '삭제', isSlot: true };
			this.tableHeaders = [...baseHeaders, ...dateHeaders, actionsHeader];
		},
		handleSelectionChange(selected) {
			this.tableSelectedItem = selected;
		},
		getToday() {
			const date = new Date();
			const year = date.getFullYear();
			const month = ("0" + (1 + date.getMonth())).slice(-2);
			const day = ("0" + date.getDate()).slice(-2);
			return year + month + day;
		},
		deepClone(obj) {
			if (obj === null || typeof obj !== "object") return obj;
			const result = Array.isArray(obj) ? [] : {};
			Object.keys(obj).forEach(k => {
				result[k] = this.deepClone(obj[k]);
			});
			return result;
		},
		download() {
			var dates = [];
			this.data.forEach(e => {
				e.ranklist.forEach(f => {
					if (!dates.includes(f.date)) {
						dates.push(f.date);
					}
				});
			});
			dates.sort((a,b) => b - a);
			var arr = [];
			this.data.forEach(e => {
				var obj = {
					상품명: e.itemName,
					노출ID: e.productId,
					옵션ID: e.vendorItemId,
					키워드: e.keyword
				};
				dates.forEach(d => {
					const found = e.ranklist.find(f => f.date == d);
					obj[d] = found ? found.totalrank : "";
				});
				arr.push(this.deepClone(obj));
			});
			const wb = XLSX.utils.book_new();
			const ws = XLSX.utils.json_to_sheet(arr);
			XLSX.utils.book_append_sheet(wb, ws, '랭킹추적현황');
			XLSX.writeFile(wb, `랭킹추적현황_${this.getToday()}.xlsx`);
		},
		collectRank() {
			if (this.saleslists.length === 0) {
				alert('수집된 상품이 없습니다. 확장프로그램에서 상품을 먼저 수집해 주세요');
				return;
			}
			const today = this.getTodaywith();
			let list = [];
			let filteredArr = [];
			let collectKeyword = confirm('수집되지 않은 키워드만 수집하시겠습니까?');
			if (this.tableSelectedItem.length === 0) {
				if (!confirm('전체 상품의 랭킹을 수집하시겠습니까?\n새탭에 쿠팡윙이 로그인되어 있어야 조회수가 수집됩니다.\nip차단 방지를 위해 반드시 휴대폰테더링으로 접속해 주세요.')) {
					return;
				} else {
					if (collectKeyword) {
						this.saleslists.forEach(f => {
							f.키워드.forEach(ele => {
								const target = this.data
									.filter(e => e.ranklist[e.ranklist.length-1].date !== today)
									.find(e => e.productId == f.productId && e.keyword == ele);
								if (target) list.push(target);
							});
						});
						filteredArr = this.data.filter(e => e.ranklist[e.ranklist.length-1].date !== today);
						this.saleslists.forEach(f => {
							f.키워드.forEach(ele => {
								const found = filteredArr.find(e => e.productId == f.productId && e.keyword == ele);
								if (found) list.push(found);
							});
						});
					} else {
						filteredArr = this.data;
						this.saleslists.forEach(f => {
							f.키워드.forEach(ele => {
								const found = filteredArr.find(e => e.productId == f.productId && e.keyword == ele);
								if (found) list.push(found);
							});
						});
						list = this.data;
					}
				}
			} else {
				const checkedItems = this.tableSelectedItem;
				if (!confirm(`${checkedItems.length}개 상품의 랭킹을 수집하시겠습니까?\n새탭에 쿠팡윙이 로그인되어 있어야 조회수가 수집됩니다.\nip차단 방지를 위해 반드시 휴대폰테더링으로 접속해 주세요.`)) {
					return;
				} else {
					const productIds = checkedItems.map(e => e.productId);
					if (collectKeyword) {
						filteredArr = this.data.filter(e => productIds.includes(e.productId)
							&& (e.ranklist[e.ranklist.length-1].date !== today));
						this.saleslists.forEach(f => {
							f.키워드.forEach(ele => {
								const found = filteredArr.find(e => e.productId == f.productId && e.keyword == ele);
								if (found) list.push(found);
							});
						});
					} else {
						filteredArr = this.data.filter(e => productIds.includes(e.productId));
						this.saleslists.forEach(f => {
							f.키워드.forEach(ele => {
								const found = filteredArr.find(e => e.productId == f.productId && e.keyword == ele);
								if (found) list.push(found);
							});
						});
					}
				}
			}
			window.postMessage(
				{ greeting: "collectRank", companyid: this.$store.state.user.companyid, rankArr: list },
				"*"
			);
			this.showspinner = true;
		},
		checkalllist() {
			if (this.checkalllists) {
				this.saleslists.forEach(e => e.checked = true);
			} else {
				this.saleslists.forEach(e => e.checked = false);
			}
		},
		deletelist(saleslist, keyword) {
			if (!this.isMultipleDelete) {
				const choice = confirm("여러 키워드를 연속으로 삭제하시겠습니까? '확인'을 누르면 연속 삭제 모드로 전환됩니다.");
				this.isMultipleDelete = choice;
				if (!choice) {
					if (confirm(`"${keyword}" 키워드를 삭제하시겠습니까?`)) {
						this.performDelete(saleslist, keyword);
					}
					return;
				}
			}
			this.performDelete(saleslist, keyword);
		},
		performDelete(saleslist, keyword) {
			const idx = this.saleslists.findIndex(item => item.productId === saleslist.productId);
			if (idx !== -1) {
				const kwIdx = this.saleslists[idx].키워드.indexOf(keyword);
				if (kwIdx !== -1) {
					this.saleslists[idx].키워드.splice(kwIdx, 1);
					this.saleslists[idx].랭킹변화.splice(kwIdx, 1);
					this.saleslists[idx].순위.splice(kwIdx, 1);
					this.saleslists[idx].상세순위 = this.saleslists[idx].상세순위.filter(it => it.keyword !== keyword);
				}
				if (this.saleslists[idx].키워드.length === 0) {
					this.saleslists.splice(idx, 1);
				}
			}
			this.deleteQueue.push({ productId: saleslist.productId, 키워드: keyword });
			this.debouncedDeleteRequest();
		},
		debouncedDeleteRequest() {
			clearTimeout(this.deleteTimeout);
			this.deleteTimeout = setTimeout(() => {
				if (this.deleteQueue.length > 0) {
					axios.post('/api/analysis/deletetrackingrank', this.deleteQueue)
						.then(result => {
							if (result.data.res === 'OK') {
								this.deleteQueue = [];
							} else {
								alert('서버 삭제 실패. 다시 시도해주세요.');
							}
						})
						.catch(error => {
							console.error('삭제 요청 오류:', error);
							alert('삭제 중 오류가 발생했습니다. 다시 시도해주세요.');
						});
				}
			}, 1000);
		},
		deleteitem() {
			console.log(this.tableSelectedItem)
			const checkedArr = this.tableSelectedItem;
			if (checkedArr.length === 0) {
				alert('삭제할 항목을 선택해 주세요');
				return;
			}
			const arr = [];
			for (const saleslist of checkedArr) {
				for (const k of saleslist.키워드) {
					arr.push({ productId: saleslist.productId, 키워드: k });
				}
			}
			if (confirm(`${checkedArr.length}개의 항목을 삭제하시겠습니까?`)) {
				axios.post('/api/analysis/deletetrackingrank', arr)
					.then(result => {
						if (result.data.res === 'OK') {
							this.search();
						} else {
							alert('삭제실패');
						}
					});
			}
		},
		modalID(index) {
			return 'modal' + index;
		},
		// (A) 검색 (DB에서 Trackingrank 불러오기)
		async search() {
			this.showspinner = true;
			this.showlist = false;
			await this.getGrowthSales();
			axios.post('/api/analysis/getranking', {
				companyid: this.$store.state.user.companyid
			})
			.then(result => {
				if (result.data.length > 0) {
				this.data = result.data;

				// (1) rowOrder / keywordOrder가 없으면 기본 할당
				//     예시: productId별로 오름차순 정렬 → rowOrder = 0,1,2,...
				//     (필요없는 경우 주석 처리해도 됨)
				const allPids = [...new Set(this.data.map(doc => doc.productId))]
					.sort((a,b) => Number(a) - Number(b));
				allPids.forEach((pid, rowIdx) => {
					this.data
					.filter(d => d.productId === pid)
					.forEach(d => {
						if (d.rowOrder === undefined) {
						d.rowOrder = rowIdx;
						}
						// keywordOrder도 필요하다면 여기서 할당 가능
					});
				});

				// (2) rowOrder 기준으로 전체 정렬
				//     → 화면에서 상품(행) 순서가 rowOrder 순으로 나옴
				this.data.sort((a,b) => a.rowOrder - b.rowOrder);

				// (3) originalData 복사 (변경 감지용)
				this.originalData = JSON.parse(JSON.stringify(this.data));

				// (4) 화면 표시
				this.searchresult();
				} else {
				alert('추가된 모니터링 상품이 없습니다.');
				this.showspinner = false;
				}
			})
			.catch(err => {
				console.error(err);
				this.showspinner = false;
				alert('데이터 조회 실패');
			});
		},
		async getGrowthSales(){
			this.growthSales = [];
			var result = await axios.post('/api/getGrowthSalesV2');
			if(result.data.res == 'success'){
				if(result.data.growthSales.length > 0){
					for(var e of result.data.growthSales){
						if(this.growthSales.length > 0 && this.growthSales.find(f => f.productId == e.productId)){
							for(var i=0;i<this.growthSales.find(f => f.productId == e.productId).trafficInfo.length;i++){
								this.growthSales.find(f => f.productId == e.productId).trafficInfo[i].sale += e.salesHistory[i];
								this.growthSales.find(f => f.productId == e.productId).trafficInfo[i].click += e.pageViewHistory[i];
							}
						} else {
							e.dates = this.generateDatesFromUpdateDate(e.updateDate);
							var trafficInfo = []
							for(i=0;i<e.dates.length;i++){
								trafficInfo.push({
									date : e.dates[i],
									sale : e.salesHistory[i],
									click : e.pageViewHistory[i]
								})
							}
							this.growthSales.push({
								productId:e.productId,
								trafficInfo:trafficInfo
							})
						}
					}
					console.log(this.growthSales)
					this.showTrafficDetail = true;
					this.showTraffic = true;
				} else {
					this.showTrafficDetail = false;
				}
			} else {
				this.showTrafficDetail = false;
			}
		},
		generateDatesFromUpdateDate(updateDate) {
			if (!updateDate) return [];
			const baseDate = new Date(updateDate);
			// updateDate의 전날을 기준으로 설정
			baseDate.setDate(baseDate.getDate() - 1);
			const dates = [];
			// 이전 30일을 생성 (오름차순 정렬)
			for (let i = 29; i >= 0; i--) {
				const d = new Date(baseDate);
				d.setDate(baseDate.getDate() - i);
				dates.push(d.toISOString().split('T')[0]);
			}
			return dates;
		},
		// (B) 화면에 뿌리기
		searchresult() {
			console.log(this.data);
			

			// 날짜/요일 표기를 위한 배열들
			this.dates = [];
			this.datesinit = [];
			for (let i = 0; i < 20; i++) {
				let day = new Date(this.getdday(i));
				const WEEKDAY = ['일', '월', '화', '수', '목', '금', '토'];
				let week = WEEKDAY[day.getDay()];
				let output = this.getdday(i).slice(5) + " (" + week + ")";
				this.dates.push(output);
				this.datesinit.push(this.getdday(i));
			}

			// 1) productId 기준으로 데이터를 묶는다
			const groupedData = this.data.reduce((acc, cur) => {
				if (!acc[cur.productId]) {
				acc[cur.productId] = {
					썸네일: cur.image,
					판매상품명: cur.itemName,
					productId: cur.productId,
					// url: `https://www.coupang.com/vp/products/${cur.productId}?itemId=${cur.itemId}&vendorItemId=${cur.vendorItemId}`,
					url: `https://www.coupang.com/vp/products/${cur.productId}`,
					키워드: [],
					랭킹변화: [],
					순위: [],
					상세순위: [],
					조회수: [],
					클릭수: [],
					판매수: [],
				};
				}
				// 중복 키워드 방지
				if (!acc[cur.productId].키워드.includes(cur.keyword)) {
					acc[cur.productId].키워드.push(cur.keyword);
				}
				return acc;
			}, {});

			// 2) groupedData 각 상품에 대해 ranklist / 조회수 / 랭킹변화 / 상세순위 계산
			Object.values(groupedData).forEach(item => {
				item.순위 = [];
				item.랭킹변화 = [];
				item.상세순위 = [];

				// (2-1) 조회수: 이 상품(productId)에 해당하는 문서 중 ranklist 확인

				const clickData = this.data.find(d => d.productId === item.productId)?.ranklist || [];
				item.조회수 = this.datesinit.map(date => {
				const clickItem = clickData.find(r => r.date === date);
				return clickItem?.click || '-';
				});

				const trafficData = this.growthSales?.find(d => d.productId === item.productId)?.trafficInfo || [];
				item.클릭수 = this.datesinit.map(date => {
					const clickItem = trafficData.find(r => r.date === date);
					return clickItem?.click || '-';
				});
				item.판매수 = this.datesinit.map(date => {
					const clickItem = trafficData.find(r => r.date === date);
					return clickItem?.sale || '-';
				});

				// (2-2) 키워드별 순위/랭킹변화/상세순위
				item.키워드.forEach(keyword => {
				// 이 상품 + 해당 키워드를 가진 문서들
				const rankData = this.data.filter(d => d.productId === item.productId && d.keyword === keyword);

				// 날짜별 순위
				const ranks = this.datesinit.map(date => {
					const rankItem = rankData
					.find(d => d.ranklist.some(r => r.date === date))
					?.ranklist.find(r => r.date === date);
					return rankItem?.totalrank !== undefined ? rankItem.totalrank : '-';
				});
				item.순위.push(ranks);

				// 랭킹변화 (맨 앞 두 값 비교)
				const rankChanges = ranks.slice(1).map((curVal, idx) => {
					const prevVal = ranks[idx];
					if (curVal === '-' || prevVal === '-') return 0;
					return curVal - prevVal;
				});
				item.랭킹변화.push(rankChanges[0] || 0);

				// 상세순위
				const detailRanks = rankData[0].ranklist.map(r => ({
					date: r.date,
					rank: r.totalrank,
					change: 0,
				})).reverse();
				detailRanks.forEach((r, idx2) => {
					if (idx2 < detailRanks.length - 1) {
					r.change = detailRanks[idx2 + 1].rank - r.rank;
					}
				});
				item.상세순위.push({ keyword, ranks: detailRanks });
				});

				// ─────────────────────────────────────────────
				// (2-3) ★ 키워드 정렬: keywordOrder 기준
				// ※ 키워드뿐 아니라 item.순위 등도 같은 인덱스이므로 같이 묶어서 정렬해야 함
				const docsForProduct = this.data.filter(d => d.productId === item.productId);

				// 임시 배열에 (키워드, 순위, 랭킹변화, 상세순위) 묶어서 담기
				const tempList = item.키워드.map((kw, i) => {
				return {
					kw,
					ranks: item.순위[i],
					changeVal: item.랭킹변화[i],
					detail: item.상세순위[i]
				};
				});

				// 임시 배열을 keywordOrder ASC로 정렬
				tempList.sort((a, b) => {
				const docA = docsForProduct.find(x => x.keyword === a.kw);
				const docB = docsForProduct.find(x => x.keyword === b.kw);

				// keywordOrder가 없으면 큰 수로 처리(= 맨 뒤)
				const orderA = (docA && docA.keywordOrder !== undefined) ? docA.keywordOrder : 999999;
				const orderB = (docB && docB.keywordOrder !== undefined) ? docB.keywordOrder : 999999;
				return orderA - orderB;
				});

				// 정렬된 결과를 다시 item.키워드 / item.순위 / item.랭킹변화 / item.상세순위에 반영
				item.키워드      = tempList.map(t => t.kw);
				item.순위       = tempList.map(t => t.ranks);
				item.랭킹변화    = tempList.map(t => t.changeVal);
				item.상세순위    = tempList.map(t => t.detail);
				// ─────────────────────────────────────────────
			});

			// 3) 최종 배열화
			this.saleslists = Object.values(groupedData);

			// 4) 화면 표시
			setTimeout(async () => {
				this.showlist = true;
				this.showspinner = false;
				// const res = await core.checkpage();
				// console.log(res);
			}, 10);
			},
		getdday(i) {
			return this.getDatewith(
				this.datetotimestamp(this.getTodaywith()) - 1000 * 60 * 60 * 24 * i
			);
		},
		getTodaywith() {
			const date = new Date();
			const y = date.getFullYear();
			const m = ("0" + (1 + date.getMonth())).slice(-2);
			const d = ("0" + date.getDate()).slice(-2);
			return `${y}-${m}-${d}`;
		},
		getDatewith(d) {
			const date = new Date(d);
			const y = date.getFullYear();
			const m = ("0" + (1 + date.getMonth())).slice(-2);
			const dd = ("0" + date.getDate()).slice(-2);
			return `${y}-${m}-${dd}`;
		},
		datetotimestamp(date) {
			return new Date(date).getTime();
		},
		// (E) "순서저장" 버튼
		saveOrder() {
			const changedDocs = [];
			this.data.forEach(doc => {
				// originalData에서 같은 _id 찾기
				const oldDoc = this.originalData.find(o => o._id === doc._id);
				if (!oldDoc) {
					// 새 문서 (과거에 없던 것)
					if (doc.rowOrder !== undefined || doc.keywordOrder !== undefined) {
						changedDocs.push(doc);
					}
				} else {
					const rowChanged = (oldDoc.rowOrder !== doc.rowOrder);
					const keyChanged = (oldDoc.keywordOrder !== doc.keywordOrder);
					if (rowChanged || keyChanged) {
						changedDocs.push(doc);
					}
				}
			});
			if (changedDocs.length === 0) {
				alert('변경된 순서가 없습니다.');
				return;
			}
			console.log(changedDocs)
			axios.post('/api/analysis/updaterankingall', changedDocs)
				.then(res => {
					if (res.data.요청결과 === 'success' || res.data.요청결과 === 'partial success') {
						alert('순서가 저장되었습니다.');
						// 저장 성공 시 originalData 갱신
						changedDocs.forEach(cDoc => {
							const idx = this.originalData.findIndex(o => o._id === cDoc._id);
							if (idx === -1) {
								this.originalData.push(JSON.parse(JSON.stringify(cDoc)));
							} else {
								this.$set(this.originalData, idx, JSON.parse(JSON.stringify(cDoc)));
							}
						});
					} else {
						alert('순서 저장 중 오류가 발생했습니다.');
					}
				})
				.catch(err => {
					console.error(err);
					alert('네트워크 오류로 순서저장 실패.');
				});
		},
		// 순서 편집 관련 새 메서드들
		toggleOrderEditMode() {
			this.isOrderEditMode = !this.isOrderEditMode;
			if (this.isOrderEditMode) {
				// 현재 순서 저장
				this.originalOrder = {
					saleslists: JSON.parse(JSON.stringify(this.saleslists)),
					data: JSON.parse(JSON.stringify(this.data))
				};
				this.selectedItem = null;
				this.selectedKeyword = null;
			} else {
				this.resetOrderEdit();
			}
		},

		selectItem(item) {
			// 전체 객체를 저장하는 대신 필요한 정보만 저장
			this.selectedItem = {
				productId: item.productId,
				index: this.saleslists.findIndex(i => i.productId === item.productId)
			};
			this.selectedKeyword = null;
		},

		selectKeyword(itemIndex, keywordIndex, keyword) {
			this.selectedKeyword = {
				itemIndex,
				keywordIndex,
				keyword
			};
			this.selectedItem = null;
		},

		moveToTop() {
			if (this.selectedItem) {
				const index = this.saleslists.findIndex(item => item.productId === this.selectedItem.productId);
				if (index > 0) {
					const item = this.saleslists.splice(index, 1)[0];
					this.saleslists.unshift(item);
					this.selectedItem = item;
					this.updateRowOrders();
					this.hasOrderChanged = true;
				}
			} else if (this.selectedKeyword) {
				const item = this.saleslists[this.selectedKeyword.itemIndex];
				const keywordArr = item.키워드;
				const keyword = keywordArr.splice(this.selectedKeyword.keywordIndex, 1)[0];
				keywordArr.unshift(keyword);
				this.moveRelatedArrays(item, this.selectedKeyword.keywordIndex, 0);
				this.updateKeywordOrders(item);
				this.hasOrderChanged = true;
				// 선택된 키워드의 인덱스를 0으로 업데이트
				this.selectedKeyword.keywordIndex = 0;  // 추가된 부분
			}
		},

		moveUp() {
			if (this.selectedItem) {
				const index = this.saleslists.findIndex(item => item.productId === this.selectedItem.productId);
				if (index > 0) {
					// 배열 요소 교환
					const temp = { ...this.saleslists[index] };
					this.$set(this.saleslists, index, { ...this.saleslists[index - 1] });
					this.$set(this.saleslists, index - 1, temp);
					
					// 선택된 항목의 인덱스 업데이트
					this.selectedItem.index = index - 1;
					
					this.updateRowOrders();
					this.hasOrderChanged = true;
				}
			} else if (this.selectedKeyword) {
				const item = this.saleslists[this.selectedKeyword.itemIndex];
				const currentIndex = this.selectedKeyword.keywordIndex;
				if (currentIndex > 0) {
					this.swapKeywordData(item, currentIndex, currentIndex - 1);
					this.updateKeywordOrders(item);
					this.hasOrderChanged = true;
					this.selectedKeyword.keywordIndex = currentIndex - 1;
				}
			}
		},

		moveDown() {
			if (this.selectedItem) {
				const index = this.saleslists.findIndex(item => item.productId === this.selectedItem.productId);
				if (index < this.saleslists.length - 1) {
					// 배열 요소 교환
					const temp = { ...this.saleslists[index] };
					this.$set(this.saleslists, index, { ...this.saleslists[index + 1] });
					this.$set(this.saleslists, index + 1, temp);
					
					// 선택된 항목의 인덱스 업데이트
					this.selectedItem.index = index + 1;
					
					this.updateRowOrders();
					this.hasOrderChanged = true;
				}
			} else if (this.selectedKeyword) {
				const item = this.saleslists[this.selectedKeyword.itemIndex];
				const currentIndex = this.selectedKeyword.keywordIndex;
				if (currentIndex < item.키워드.length - 1) {
					this.swapKeywordData(item, currentIndex, currentIndex + 1);
					this.updateKeywordOrders(item);
					this.hasOrderChanged = true;
					this.selectedKeyword.keywordIndex = currentIndex + 1;
				}
			}
		},

		moveToBottom() {
			if (this.selectedItem) {
				const index = this.saleslists.findIndex(item => item.productId === this.selectedItem.productId);
				if (index < this.saleslists.length - 1) {
					const item = this.saleslists.splice(index, 1)[0];
					this.saleslists.push(item);
					this.selectedItem = item;
					this.updateRowOrders();
					this.hasOrderChanged = true;
				}
			} else if (this.selectedKeyword) {
				const item = this.saleslists[this.selectedKeyword.itemIndex];
				const lastIndex = item.키워드.length - 1;
				const keyword = item.키워드.splice(this.selectedKeyword.keywordIndex, 1)[0];
				item.키워드.push(keyword);
				this.moveRelatedArrays(item, this.selectedKeyword.keywordIndex, lastIndex);
				this.updateKeywordOrders(item);
				this.hasOrderChanged = true;
				// 선택된 키워드의 인덱스를 마지막으로 업데이트
				this.selectedKeyword.keywordIndex = lastIndex;  // 추가된 부분
			}
		},

		moveRelatedArrays(item, fromIndex, toIndex) {
			if (!item) return;  // 안전 검사 추가
			
			const arrays = ['랭킹변화', '순위', '상세순위'];
			arrays.forEach(arrayName => {
				if (item[arrayName] && Array.isArray(item[arrayName])) {
					const element = item[arrayName].splice(fromIndex, 1)[0];
					item[arrayName].splice(toIndex, 0, element);
				}
			});
		},

		swapKeywordData(item, index1, index2) {
			const arrays = ['키워드', '랭킹변화', '순위', '상세순위'];
			arrays.forEach(arrayName => {
				if (item[arrayName]) {
					const temp = item[arrayName][index1];
					this.$set(item[arrayName], index1, item[arrayName][index2]);
					this.$set(item[arrayName], index2, temp);
				}
			});
		},

		updateRowOrders() {
			// saleslists의 각 항목에 대해 인덱스를 기준으로 rowOrder 업데이트
			this.data.forEach(doc => {
				const item = this.saleslists.find(item => item.productId === doc.productId);
				if (item) {
					const index = this.saleslists.indexOf(item);
					doc.rowOrder = index;
				}
			});
		},

		updateKeywordOrders(item) {
			item.키워드.forEach((keyword, index) => {
				const doc = this.data.find(d => d.productId === item.productId && d.keyword === keyword);
				if (doc) {
					doc.keywordOrder = index;
				}
			});
		},

		cancelOrderEdit() {
			if (this.hasOrderChanged) {
				if (confirm('변경된 순서가 있습니다. 취소하시겠습니까?')) {
					this.resetOrderEdit();
				}
			} else {
				this.resetOrderEdit();
			}
		},

		resetOrderEdit() {
			if (this.originalOrder) {
				this.saleslists = JSON.parse(JSON.stringify(this.originalOrder.saleslists));
				this.data = JSON.parse(JSON.stringify(this.originalOrder.data));
			}
			this.isOrderEditMode = false;
			this.selectedItem = null;
			this.selectedKeyword = null;
			this.hasOrderChanged = false;
		},

		highlightKeywordRow(rowIndex, keywordIndex) {
			this.highlightedRow = rowIndex;
			this.highlightedKeywordIndex = keywordIndex;
		},

		clearHighlight() {
			this.highlightedRow = null;
			this.highlightedKeywordIndex = null;
		}
	}
};
</script>

<style scoped>
.keyword-row {
    padding: 2px 0;
    display: flex;
    align-items: center;
}

.order-edit-toolbar {
    background-color: #f8f9fa;
    padding: 8px 16px;
    border-radius: 4px;
    border: 1px solid #dee2e6;
    margin-bottom: 16px;
}

input[type="radio"] {
    cursor: pointer;
    width: 16px;
    height: 16px;
}

.selected-item {
    background-color: rgba(0, 123, 255, 0.1);
}
.row-highlight {
    background-color: rgba(0, 123, 255, 0.05);
}

.keyword-row-highlight {
    background-color: #00a0d4;
	color:white;
	font-weight:bolder;
}
.action-row {
    height: 21px;  /* 각 행의 높이 고정 */
    display: flex;
    align-items: center;
    justify-content: center;
}

.placeholder-row {
    height: 21px;  /* 각 행의 높이 고정 */
    display: flex;
    align-items: center;
    justify-content: center;
}
</style>